import { CssBaseline } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { MatxTheme } from './components';
import { AuthProvider } from './contexts/AmplifyAuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import routes from './routes';
import { Amplify } from 'aws-amplify';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';

// if running in local dev mode, use the local amplify config, otherwise use the one from the window object
if (process.env.NODE_ENV === 'development') {
  Amplify.configure({
    Auth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_fY3r2jjAC',
      userPoolWebClientId: 'dgehk1n8lljunt95g29dtgv8u',
      identityPoolId: 'eu-west-1:ca5f88bf-f56b-4211-813d-da711fb5ed9c',
      mandatorySignIn: true,
      oauth: {
        domain: 'tvappsdashuserpooldev.auth.eu-west-1.amazoncognito.com',
        scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'http://localhost:5173',
        redirectSignOut: 'http://localhost:5173',
        responseType: 'code'
      }
    },
    API: {
      endpoints: [
        {
          name: 'Showrunner',
          endpoint: 'https://ultimatecheckadmin.dev-tvapps.btcsp.co.uk',
          region: 'eu-west-1'
        },
        {
          name: 'Email',
          endpoint: 'https://ultimatecheckemail.dev-tvapps.btcsp.co.uk',
          region: 'eu-west-1'
        },
        {
          name: 'ClientHosting',
          endpoint: 'https://clienthstng.dev-tvapps.btcsp.co.uk',
          region: 'eu-west-1'
        },
        {
          name: 'Precision',
          endpoint: 'https://gnsconviva.dev-tvapps.btcsp.co.uk',
          region: 'eu-west-1'
        }
      ]
    }
  });
} else {
  Amplify.configure(window.awsconfig);
}

const App = () => {
  const content = useRoutes(routes);

  return (
    <SettingsProvider>
      <AuthProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          <MatxTheme>
            <CssBaseline />
            {content}
          </MatxTheme>
        </LocalizationProvider>;
      </AuthProvider>
    </SettingsProvider>
  );
};

export default App;
